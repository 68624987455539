import axios from 'axios';
import qs from 'query-string';

import { SERVER_URL, ROOT_URL } from '../../utils/environment';

import { COMPLETED_SIGNIN, logEvent, setUserId } from '../../utils/amplitude';

const redirectToApp = (token, url, params = null) => {
  const inkblotUrl = `${url}/redirect/${token}${
    params ? `?${qs.stringify(params)}` : ''
  }`;
  window.location.replace(inkblotUrl);
};

const redirect = (response, url) => {
  const userId = response.data.user_id;

  setUserId(userId);
  logEvent(COMPLETED_SIGNIN);

  const { access_token: token } = response.data;

  if (token) {
    // redirect partner to registration on login with existing account
    if (window.location.search.includes('partner_registration=true')) {
      redirectToApp(token, url, { partner_registration: true });
    } else {
      redirectToApp(token, url, null);
    }
  }
};

const signIn = ({ email, locale, password, source }) => {
  const body = {
    email,
    locale,
    password,
    source,
  };
  return axios
    .post(`${SERVER_URL}/api/login`, body)
    .then((response) => {
      const { gsplus_migration, reseller_name, company_name } = response.data;

      if (!gsplus_migration) {
        redirect(response, ROOT_URL);
      }

      return {
        gsplusMigration: gsplus_migration,
        resellerName: reseller_name,
        company_name,
      };
    })
    .catch((mainError) => ({ error: mainError.response.data.error }));
};
export default signIn;
