import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InkblotLogo from 'images/SignUp/inkblot-logo.svg';
import SVG from 'react-inlinesvg';
import { reduxForm, Field } from 'redux-form/immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  COUNTRY,
  MARKETING_URL,
  OTHER_THERAPY_URL,
  PRACTICE_URL,
  REGISTRATION_URL,
} from 'utils/environment';
import { validEmail } from 'utils/validation';

// squid
import { SqTextInputWithLabel } from 'components/SqTextInput/TextInput';
import RadioCard from 'components/SqRadioCard/RadioCard';
import SqAlert from 'components/SqAlert/Alert';
import SqButton from 'components/SqButton/SqButton';
import LanguageSelector from 'containers/LanguageSelector';
import { minTabletSize, phoneSize, teenyWeenySize } from '../../global-styles';
import messages from './messages';
import signIn from './SignIn';
import { checkIdentifier } from './checkIdentifier';
import MigrationDialog from './MigrationDialog';
import FirstCanadianMigrationDialog from './FirstCanadianMigrationDialog';
import FppmMigrationDialog from './FppmMigrationDialog';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 30px;
  min-height: 100vh;
  z-index: 100;
  max-height: 100vh;
  overflow-y: scroll;

  @media (min-width: ${teenyWeenySize}) {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    align-items: center;
  }
  @media (min-width: ${phoneSize}) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  @media (min-width: ${minTabletSize}) {
    padding-left: 11%;
    padding-right: 10px;
    width: 45%;
    align-items: left;
  }
`;

const SignupFormElementsWrapper = styled.div`
  max-width: 374px;
  width: 100%;
`;

const AlreadyHaveAnAccountWrapper = styled.div`
  margin-top: 10px;
  > * {
    margin-top: 8px;
  }
`;

const StyledLabel = styled.span`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #696e82;
  margin-bottom: 8px;
`;

const CountrySelectorButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  margin-bottom: 30px;
`;
const StyledRadioCard = styled(RadioCard)`
  width: 143px;
  height: 44px;
`;

const StyledFakeLink = styled.span`
  color: #5d70ba;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 374px;
  width: 100%;
  margin-bottom: 45px;
`;
const PageTitle = styled.span`
  font-family: 'P22 Mackinac Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  color: #282d40;
`;

const StyledForm = styled.form`
  > * {
    margin-bottom: 30px;
    max-width: 374px;
    width: 100%;
  }
`;
const SignInForm = (props) => {
  const { intl, history, submitting } = props;

  const { locale } = intl;
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY);
  const passwordField = useRef();
  const [loginError, setLoginError] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [migrationPopup, setMigrationPopup] = useState(false);
  const [fppmMigrationPopup, setFppmMigrationPopup] = useState(false);
  const [firstCanadianMigrationPopup, setFirstCanadianMigrationPopup] =
    useState(false);
  const formRef = useRef();
  useEffect(() => {
    if (selectedCountry !== COUNTRY) {
      window.location.href = `${OTHER_THERAPY_URL}/signin`;
    }
  }, [selectedCountry]);

  const [hasEnteredIdentifier, setHasEnteredIdentifier] = useState(false);

  const onIdentifierEntered = async (email) => {
    try {
      const result = await checkIdentifier(email);
      if (result.authentication_method === 'DHE') {
        const query = result.azure_policy_id
          ? `?policy_id=${result.azure_policy_id}`
          : '';
        history.push(`/digital-health-ecosystem/${query}`);

        return;
      }
    } finally {
      setHasEnteredIdentifier(true);
    }
  };

  useEffect(() => {
    if (!hasEnteredIdentifier) return;
    passwordField.current.focus();
  }, [hasEnteredIdentifier]);

  const onSubmit = async (values) => {
    const { email, password } = values.toJS();
    if (!hasEnteredIdentifier) {
      onIdentifierEntered(email);
      return;
    }

    const res = await signIn({
      email,
      password,
      locale,
      source: 'therapy',
    });

    if (res?.gsplusMigration) {
      let popup = setMigrationPopup;
      if (res?.resellerName === 'First Canadian Insurance Corporation')
        popup = setFirstCanadianMigrationPopup;
      if (
        res?.company_name ===
          'Fraternité des policiers et policières de Montréal (pour employés)' ||
        res?.company_name ===
          'Fraternité des policiers et policières de Montréal (FPPM)'
      )
        popup = setFppmMigrationPopup;
      popup(true);
    }

    if (res?.error) {
      setLoginError(res.error);
    }
  };

  const shouldShowForgotPassword = hasEnteredIdentifier;

  return (
    <Panel>
      <StyledHeader>
        <SVG src={InkblotLogo} alt="right icon" />
        <LanguageSelector locale={locale.toUpperCase()} />
      </StyledHeader>
      <SignupFormElementsWrapper>
        <PageTitle>
          <FormattedMessage {...messages.title} />
        </PageTitle>
        <AlreadyHaveAnAccountWrapper>
          <p>
            <FormattedMessage
              {...messages.dontHaveAcc}
              values={{
                link: (
                  <StyledFakeLink
                    onClick={() => {
                      window.location.href = `${REGISTRATION_URL}`;
                    }}
                  >
                    <FormattedMessage {...messages.signup} />
                  </StyledFakeLink>
                ),
              }}
            />
          </p>
        </AlreadyHaveAnAccountWrapper>
        <hr style={{ width: '100px', marginLeft: '0', marginRight: 'auto' }} />
        <div style={{ marginBottom: '8px' }}>
          <StyledLabel>
            <FormattedMessage {...messages.country} />
          </StyledLabel>
        </div>
        <CountrySelectorButtonWrapper>
          <StyledRadioCard
            onClick={() => {
              setSelectedCountry('CA');
            }}
            initValue={COUNTRY === 'CA'}
            label="Canada"
            style={{ marginRight: '14px' }}
          />
          <StyledRadioCard
            onClick={() => setSelectedCountry('US')}
            label={<FormattedMessage {...messages.america} />}
            initValue={COUNTRY === 'US'}
          />
        </CountrySelectorButtonWrapper>
        <StyledForm ref={formRef} onSubmit={props.handleSubmit(onSubmit)}>
          <Field
            component={SqTextInputWithLabel}
            name="email"
            label={<FormattedMessage {...messages.email} />}
            type="text"
            validate={[requiredValidate, emailValidate]}
          />
          <div style={{ display: hasEnteredIdentifier ? 'block' : 'none' }}>
            <Field
              props={{ forwardedRef: passwordField }}
              component={SqTextInputWithLabel}
              name="password"
              label={<FormattedMessage {...messages.password} />}
              type={passwordVisible ? 'text' : 'password'}
              rightIconName={passwordVisible ? 'Eye' : 'EyeOff'}
              rightIconOnclick={() => setPasswordVisible(!passwordVisible)}
              style={{ width: '100%' }}
              rightIconAriaLabel={
                passwordVisible ? 'Hide password' : 'Show password'
              }
              validate={hasEnteredIdentifier ? [requiredValidate] : []}
            />
          </div>
          <SqButton
            type="submit"
            disabled={submitting}
            label={
              <FormattedMessage
                {...(hasEnteredIdentifier ? messages.title : messages.next)}
              />
            }
            style={{ width: '109px', height: '56px', marginBottom: '16px' }}
          />
        </StyledForm>
        {shouldShowForgotPassword && (
          <StyledFakeLink
            tabIndex="0"
            onClick={() => {
              window.location.href = `${MARKETING_URL}/forgot_password`;
            }}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                window.location.href = `${MARKETING_URL}/forgot_password`;
              }
            }}
          >
            <FormattedMessage {...messages.forgotPassword} />
          </StyledFakeLink>
        )}
        <div style={{ marginTop: '10px' }}>
          <StyledFakeLink
            tabIndex="0"
            onClick={() => {
              window.location.href = `${PRACTICE_URL}/signin`;
            }}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                window.location.href = `${PRACTICE_URL}/signin`;
              }
            }}
          >
            <FormattedMessage {...messages.ifYouAreACounsellor} />
          </StyledFakeLink>
        </div>
        {loginError && (
          <SqAlert
            type="critical"
            text={loginError}
            style={{ marginTop: '16px' }}
          />
        )}
        <SqAlert
          style={{ marginTop: '64px' }}
          type="infoCritical"
          text={
            COUNTRY === 'US' ? (
              `If you or someone you know is in crisis and requires immediate support, call the 988 Suicide & Crisis Lifeline or go to your nearest emergency room. Please know you are not alone. Support is available`
            ) : (
              <FormattedMessage {...messages.crisisAlert} />
            )
          }
        />
      </SignupFormElementsWrapper>
      <MigrationDialog
        open={migrationPopup}
        onClose={() => setMigrationPopup(false)}
      />
      <FirstCanadianMigrationDialog
        open={firstCanadianMigrationPopup}
        onClose={() => setFirstCanadianMigrationPopup(false)}
      />
      <FppmMigrationDialog
        open={fppmMigrationPopup}
        onClose={() => setFppmMigrationPopup(false)}
      />
    </Panel>
  );
};

export default reduxForm({
  form: 'SignInForm',
})(injectIntl(withRouter(SignInForm)));

function requiredValidate(value) {
  if (!value) {
    return <FormattedMessage {...messages.required} />;
  }
}

function emailValidate(value) {
  if (!validEmail.test(value)) {
    return <FormattedMessage {...messages.enterAnEmailWithExample} />;
  }
}
